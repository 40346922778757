html {
  background-color: #f4f4f4;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;

  background-color: #f4f4f4;
  text-align: center;
}


.congrats-container {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 40;
  text-align: center;
}

.wordart{
    font-family: Arial, sans-serif;
    font-size: 2em;
    font-weight: bold;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    z-index: 1;
}

.wordart.blues .text {
    font-family: Impact, sans-serif;
    color: #24c0fd;
    -webkit-text-stroke: 0.01em #0000aa;
    filter: progid:DXImageTransform.Microsoft.Glow(Color=#0000aa,Strength=1);
    text-shadow: 0.13em -0.13em 0px #0000aa;
    letter-spacing: -0.05em;
}


.wordart {
  display: block;
  border-radius: 50%;
  position: absolute;

  -webkit-animation: moveX 2s linear 0s infinite alternate, moveY 3.4s linear 0s infinite alternate;
  -moz-animation: moveX 2s linear 0s infinite alternate, moveY 3.4s linear 0s infinite alternate;
  -o-animation: moveX 2s linear 0s infinite alternate, moveY 3.4s linear 0s infinite alternate;
  animation: moveX 2s linear 0s infinite alternate, moveY 3.4s linear 0s infinite alternate;
}

@-webkit-keyframes moveX {
  from { left: 0; } to { left: 70vw; }
}
@-moz-keyframes moveX {
  from { left: 0; } to { left: 70vw; }
}
@-o-keyframes moveX {
  from { left: 0; } to { left: 70vw; }
}
@keyframes moveX {
  from { left: 0; } to { left: 70vw; }
}

@-webkit-keyframes moveY {
  from { top: 0; } to { top:  70vh; }
}
@-moz-keyframes moveY {
  from { top: 0; } to { top:  70vh; }
}
@-o-keyframes moveY {
  from { top: 0; } to { top: 70vh; }
}
@keyframes moveY {
  from { top: 0; } to { top: 70vh; }
}
